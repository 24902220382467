import React from "react"
import Layout from "../components/Layout"
import Form from "../components/Form"
import "../styles/styles.scss"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Covid from "../components/Covid"

import Seo from "../components/Seo"

const Contact = () => {
  const contact = useStaticQuery(graphql`
    query Contact {
      logo: file(relativePath: { eq: "TheLogoMan.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Contact"
        description=" Contact us today to get started learning guitar, bass, songwriting and singing online. There are no auditions and beginners are welcome."
      />
      <Covid />
      <div className="contact-page-wrap">
        <div className="contact-logo">
          <Img
            fixed={contact.logo.childImageSharp.fixed}
            alt="The band academy Logo"
          />
          <h2>The Band Academy</h2>
        </div>
        <div className="contact-text-wrap">
          <div className="contact-text">
            <p>
              Contact us today to get started learning guitar, bass, songwriting
              and singing. There are no auditions and beginners are welcome.
            </p>

            <p>Classes are currently online and taking place via Zoom.</p>

            <a className="tel" href="tel:07415095025">
              07415 095025
            </a>
            <a className="email" href="mailto:info@thebandacademy.co.uk">
              info@thebandacademy.co.uk
            </a>
          </div>
        </div>
        <div className="contact-form">
          <h2>Contact a Coach</h2>

          <Form />
        </div>

        <div className="map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9922.445742362073!2d-2.5501962!3d51.5570237!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xecfc51fcf959506!2sThe%20Band%20Academy!5e0!3m2!1sen!2suk!4v1613150267265!5m2!1sen!2suk"
            width="600"
            height="450"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
          ></iframe>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
