import React from "react"

const Form = () => {
  return (
    <div>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="form"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div className="name">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" />
        </div>
        <div className="email">
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" />
        </div>
        <div className="message">
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows="6" />
        </div>
        <ul className="actions">
          <li>
            <input type="submit" value="ENQUIRE" className="submit" />
          </li>
          <li>
            <input type="reset" value="CLEAR"></input>
          </li>
        </ul>
      </form>
    </div>
  )
}

export default Form
